<template>
	<div>
	<ContentHeader title="Uang Duduk"  />
	<LoadingScreen v-if="loading_screen" />
     <div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
	            <div class="col">
	            	<div class="card">
	            		<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Uang Duduk</h3>
	            			<div class="col-6 text-right">
	            				<router-link to="/tambah-uang-duduk"><button class="btn btn-sm btn-info">Tambah</button></router-link>	
	            			</div>
	            		</div>
	            		<div class="card-body">
	            			<div class="row align-items-center">
	            				<div class="col-lg-2">
	            					<span>Jumlah Baris</span>
	            					<select class="custom-select form-control" v-model="page" @change="getDataResult">
		            					<option value="10">10</option>
		            					<option value="15">15</option>
		            					<option value="20">20</option>
		            					<option value="25">25</option>
		            					<option value="30">30</option>
		            					<option value="40">40</option>
		            					<option value="45">45</option>
		            					<option value="50">50</option>
		            				</select>
	            				</div>
	            				<div class="col-lg-2">
	            					<span>Periode Awal</span>
	            					<input type="date" class="form-control" v-model="periode_awal">
	            				</div>
	            				<div class="col-lg-2">
	            					<span>Periode Akhir</span>
	            					<input type="date" class="form-control" v-model="periode_akhir">
	            				</div>
	            				<div class="col-lg-1 pt-4">
	            					<button class="btn btn-primary" @click="getDataResult">Filter</button>
	            				</div>				
	            				<div class="col-lg-5"></div>
	            			</div>
	            			<table class="table table-bordered table-hover mt-4">
							  <thead>
							    <tr>
							      <th scope="col" class="text-sm">NO</th>
							      <th scope="col" class="text-sm">TANGGAL</th>
							      <th scope="col" class="text-sm">JAM</th>
							      <th scope="col" class="text-sm">DOKTER</th>
							      <th scope="col" class="text-sm">UANG DUDUK</th>
							      <th scope="col" class="text-sm">JUMLAH PASIEN</th>
							      <th scope="col" class="text-sm">ACTION</th>
							    </tr>
							  </thead>
							  <tbody>
							  	<tr v-for="(row, index) in data_result" :key="row.id">
							    	<td>{{ index+1 }}</td>
							    	<td>{{ format_tanggal(row.tanggal_kerja) }}</td>
							    	<td>{{ row.jam_kerja }}</td>
							    	<td>{{ row.dokter.nama_lengkap }}</td>
							    	<td>{{ format_nominal(row.nominal) }}</td>
							    	<td>{{ row.jumlah_pasien }}</td>
							    	<td>
						              <button @click="konfirmasiHapus(row.id)" class="btn btn-danger btn-sm">Hapus</button>
								    </td>
							    </tr>
							  </tbody>
							</table>
							<p class="text-center mt-4" v-if="memuat_data">Memuat Data...</p>
							<p class="text-center mt-4" v-if="cek_data">Data Masih Kosong</p>
	            		</div>
	            		<div class="card-footer">
							<nav aria-label="...">
							  <ul class="pagination">
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
							      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
							    </li>
							    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
							      <a class="page-link" href="#">Next</a>
							    </li>
							  </ul>
							</nav>
	            		</div>
	            	</div>
	            </div>
	        </div>
		</div>
	</div>
    </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'
import { ref, onMounted, computed } from 'vue'
import store from '@/store'
import axios from 'axios'
import format_tanggal from '@/format_tanggal.js'
import format_nominal from '@/format_nominal.js'
import LoadingScreen from '@/components/LoadingScreen'
import Swal from 'sweetalert2'

export default{
	components: {
		ContentHeader: ContentHeader,
		LoadingScreen: LoadingScreen,
	},
    setup(){
    	const user = computed(() => store.getters['auth/user'])
		const data_result = ref([])
		const memuat_data = ref(true)
		const cek_data = ref(false)
		const page = ref(10)
		const links = ref([])
		const pagination = ref([])

		const loading_screen = ref(false)
		const periode_awal = ref('')
		const periode_akhir = ref('')

		const getDataResult = async () => {
			data_result.value = []
	    	memuat_data.value = true
	    	cek_data.value = false
	    	loading_screen.value = true
	    	let tanggal_awal = ''
	    	let tanggal_akhir = ''

	    	if (periode_awal.value != '' && periode_akhir.value != '') {
				tanggal_awal = periode_awal.value
				tanggal_akhir = periode_akhir.value
			} else {
				tanggal_awal = 'emptyParameter'
				tanggal_akhir = 'emptyParameter'
			}

			let { data } = await axios.get(`api/uang_duduk/getByPeriode/${user.value.cabang_id}/${page.value}/${tanggal_awal}/${tanggal_akhir}`)

			if (data != 'kosong') {
				memuat_data.value = false
				cek_data.value = false
				data_result.value = data.data
				links.value = data.links
    			links.value.splice(0, 1)
    			links.value.splice(links.value.length-1, 1)
    			pagination.value = data
    			loading_screen.value = false
			} else {
				cek_data.value = true
				memuat_data.value = false
				data_result.value = []
    			loading_screen.value = false
			}	
		}

		const paginateData = async (url) => {

    		if (url != null) {
    			data_result.value = []
	    		memuat_data.value = true

	    		let link_url = new URL(url)
	    		link_url = `${link_url.pathname}${link_url.search}`
	    		
	    		let { data } = await axios.get(`${link_url}`)

	    		
	    		if (data != 'kosong') {
	    			memuat_data.value = false
	    			data_result.value = data.data	
	    			links.value = data.links
	    			links.value.splice(0, 1)
	    			links.value.splice(links.value.length-1, 1)
	    			pagination.value = data
	    		}
    		}
    	}

    	const konfirmasiHapus = (id) => {
	        Swal.fire({
	          title: 'Hapus Data?',
	          icon: 'warning',
	          showCancelButton: true,
	          confirmButtonText: `Ya`,
	          cancelButtonText: `Batal`,
	        }).then((result) => {
	          /* Read more about isConfirmed, isDenied below */
	          if (result.isConfirmed) {
	            hapusData(id)
	          } 
	        })
	      }

    	const hapusData = (id) => {
    		axios.delete(`api/uang_duduk/destroy/${id}`)
    		.then((response) => {
    			Swal.fire({
		            title: 'Berhasil!',
		            text: 'Berhasil Menghapus Data',
		            icon: 'success',
		            showConfirmButton: false,
		            timer: 1500
		          })
    			console.log(response.data)
    			getDataResult()
    		})
    		.catch((error) => {
    			Swal.fire({
					  title: 'Gagal!',
					  text: 'Gagal menghapus data',
					  icon: 'success',
					  confirmButtonText: 'Ok'
					})
    			console.log(error)
    		})
    	}

		onMounted(() => {
			getDataResult()
		})

		return { data_result, memuat_data, links, paginateData, pagination, getDataResult, page, cek_data, format_tanggal, format_nominal, periode_awal, periode_akhir, loading_screen, konfirmasiHapus }
    }
}
</script>

<style>
	
</style>